.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #fff;
  transition: background 250ms linear;
}

.decor {
  border-radius: 979px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(51, 48, 228, 0.5) 0%,
    rgba(51, 48, 228, 0) 100%
  );
  width: 50%;
  height: 50%;
}
