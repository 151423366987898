* {
    padding: 0;
    margin: 0;
}

html {
    padding: 0;
    margin: 0;
    background: transparent;
}

body {
    padding: 0;
    margin: 0;
    background: transparent;
}

h1,
h2,
h3,
h4,
h5,
p,
span {
    padding: 0;
    margin: 0;
}

ul,
ol,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    text-decoration: none;
}

img {
    display: block;
}
