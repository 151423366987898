.signup-select-title {
    color: #000;
    text-align: center;
    font-family: Geometria;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 9px;

    &-span {
        color: #3330e4;
    }
}

.signup-select-second {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 60px;

    @media screen and (max-width: 450px) {
        font-size: 20px;
    }
}

.signup-select-flex {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.signup-select-card {
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
    padding-bottom: 45px;
    padding-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-select-card-icon {
    margin-bottom: 30px;
}

.signup-select-card-title {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    @media screen and (max-width: 450px) {
        font-size: 24px;
    }
}

.signup-select-card-desc {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 30px;
    @media screen and (max-width: 450px) {
        font-size: 16px;
    }
}

.signup-select-text {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 40px;
}
