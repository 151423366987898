.container {
    max-width: 1350px;
    padding: 0 15px;
    margin: 0 auto;
}

.container-form {
    max-width: 894px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }
}
