.terms {
    padding: 80px 0;
}

.terms-text {
    color: #000;
    font-family: Geometria;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &-span {
        color: #ff7a09;
        font-family: Geometria;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.terms-title {
    color: var(--Blue, #3330e4);
    font-family: Geometria;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 38px;
}

.terms-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: disc;
    padding-left: 20px;

    &-item {
        list-style: disc;
        color: #000;
        font-family: Geometria;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
