.create-invoice {
    padding-bottom: 80px;
}

.create-invoice-progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    &-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;

        &-item {
            width: calc(100% / 3);

            &:nth-child(2) {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
            &:nth-child(3) {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                text-align: right;
            }

            &-title {
                color: var(--text);
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media screen and (max-width: 550px) {
                    font-size: 12px;
                }
            }

            &-desc {
                color: var(--text);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and (max-width: 550px) {
                    font-size: 10px;
                }
            }
        }
    }

    &-step {
        position: relative;
        width: 37px;
        height: 37px;
        border: 1px solid #3330e4;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 550px) {
            width: 26px;
            height: 26px;
        }

        &.active {
            background: var(--background);
        }
        &.accept {
            background: var(--background);
        }

        &.accept &-icon {
            display: block;
        }

        &.active::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: #ff7a09;

            @media screen and (max-width: 550px) {
                width: 17px;
                height: 17px;
            }
        }

        &-icon {
            display: none;
        }
    }

    &-decor {
        width: calc(50% - 58px);
        height: 1px;
        background: #3330e4;

        @media screen and (max-width: 550px) {
            width: calc(50% - 42px);
        }
    }
}

.create-invoice-second {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 85px;

    @media screen and (max-width: 550px) {
        font-size: 20px;
    }
}

.create-invoice-form {
    margin-top: 60px;
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
}

.create-invoice-form-header {
    border-radius: 30px 30px 0px 0px;
    background: #3330e4;
    padding: 25px 0;

    &-title {
        color: #fff;
        text-align: center;
        font-family: Geometria;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
    }
}

.create-invoice-form-content {
    padding: 80px 34px;
}

.create-invoice-form-content-notification {
    border-radius: 30px;
    background: rgba(51, 48, 228, 0.2);
    padding: 34px 45px;
    margin-top: 60px;

    @media screen and (max-width: 550px) {
        padding: 30px 25px;
    }

    &-text {
        color: var(--text);
        text-align: center;
        font-family: Geometria;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 550px) {
            font-size: 16px;
        }
    }
}

.create-invoice-select {
    display: flex;
    margin-top: 40px;
    
    &-button {
        width: 50%;
        border: 1px solid var(--Orange, #ff7a09);
        background: #fff;
        padding: 27px 0;

        color: #ff7a09;
        text-align: center;
        font-family: Geometria;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;

        cursor: pointer;

        transition: color 250ms linear;

        transition-property: color, background;

        @media screen and (max-width: 550px) {
            font-size: 18px;
            padding: 20px 0;
        }

        [data-theme="dark"] & {
            background: #000;
        }

        &.active {
            background: var(--Orange, #ff7a09);
            color: #fff;
        }

        [data-theme="dark"] &.active {
            color: #000;
        }

        &:hover {
            background: var(--Orange, #ff7a09);
            color: #fff;
        }

        [data-theme="dark"] &:hover {
            color: #000;
        }

        &:first-child {
            border-radius: 30px 0px 0px 30px;
        }
        &:last-child {
            border-radius: 0px 30px 30px 0px;
        }
    }
}

.create-invoice-form-content-switch {
    display: flex;
    align-items: center;
    gap: 15px;

    &-text {
        color: var(--text);
        font-family: Geometria;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 550px) {
            font-size: 18px;
        }
    }
}

.create-invoice-form-content-switch-input {
    position: relative;
    width: 52px;
    height: 25px;
    border-radius: 30px;
    background: rgba(51, 48, 228, 0.2);
    cursor: pointer;

    &-decor {
        position: absolute;
        top: 50%;
        left: 3px;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--background);

        transform: translateY(-50%);
        transition: transform 250ms linear;
        transition-property: left, transform, background;
    }

    &.active &-decor {
        left: 100%;
        transform: translate(calc(-100% - 3px), -50%);
        background: #3330e4;
    }
}

.create-invoice-balance {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 17px;

    &-span {
        color: var(--text);
        font-family: Geometria;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.create-invoice-balance-decor {
    color: var(--text);
    font-family: Geometria;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 46px;
    margin-bottom: 27px;
}

.create-invoice-wallet {
    border-radius: 30px;
    background: var(--background);
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);

    &-header {
        display: flex;
        align-items: center;
        border-radius: 30px 30px 0px 0px;
        background: #3330e4;
        padding: 25px 42px;

        &-all {
            &-button {
                width: 33px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: var(--Orange, #ff7a09);
                box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
                cursor: pointer;
            }
        }

        &-title {
            color: #fff;
            font-family: Geometria;
            font-size: 26px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
    }

    &-content {
        &-item {
            display: flex;
            align-items: center;
            padding: 25px 42px;

            &:not(:last-child) {
                border-bottom: 1px solid #3330e4;
            }

            &-code {
                color: var(--text);
                font-family: Geometria;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
            }

            &-name {
                color: var(--text);
                font-family: Geometria;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            &-due {
                color: var(--text);
                font-family: Geometria;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
