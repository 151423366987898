.account-influencer-balance {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    &-score {
        color: var(--text);
        text-align: center;
        font-family: Geometria;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
        &-span {
            font-weight: 400;
        }
    }
}

.account-influencer-details {
    padding-bottom: 80px;

    &-form {
        max-width: 550px;
        margin: 0 auto;
        padding-bottom: 50px;

        @media screen and (max-width: 768px) {
            padding: 0 20px;
            padding-bottom: 50px;
        }
    }
}

.account-influencer-details-second {
    color: var(--text);
    text-align: center;
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 70px;
}

.account-influencer-details-thoomb {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.account-influencer-details-wrapper {
    box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
    border-radius: 30px;
    background: var(--background);

    &-header {
        position: relative;
        border-radius: 30px 30px 0px 0px;
        background: #3330e4;
        padding: 25px 0;

        &-title {
            color: #fff;
            text-align: center;
            font-family: Geometria;
            font-size: 26px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-transform: uppercase;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }

        &-edit {
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    &-content {
        max-width: 665px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 25px;
        border-radius: 0px 0px 30px 30px;
        background: var(--background);
        padding: 40px 0;

        &-item {
            display: flex;
            align-items: center;

            @media screen and (max-width: 768px) {
                justify-content: center;
                flex-direction: column;
            }
        }

        &-title {
            width: 30%;
            color: var(--text);
            font-family: Geometria;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                width: 100%;
                text-align: center;
            }
        }

        &-value {
            width: 70%;
            color: var(--text);
            font-family: Geometria;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            overflow: hidden;

            @media screen and (max-width: 768px) {
                font-size: 16px;
                width: 100%;
                text-align: center;
            }
        }
    }
}
