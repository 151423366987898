.block {
  width: 100%;
  display: flex;
}

.buttonTrue {
  width: 50%;
  padding: 27px 0;
  border-radius: 0px 0px 0px 30px;
  border: 1px solid #ff7a09;
  border-right-width: 0;
  background: var(--background);

  color: var(--Orange, #ff7a09);
  text-align: center;
  font-family: Geometria;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  transition: color 250ms linear;
  transition-property: color, background;
  cursor: pointer;
}

.buttonTrue:hover {
  background: var(--Orange, #ff7a09);
  color: var(--text-hover);
}

.buttonFalse {
  width: 50%;
  padding: 27px 0;
  border-radius: 0px 0px 30px 0px;
  border: 1px solid #ff7a09;
  border-left-width: 0.5px;
  background: var(--background);

  color: var(--Orange, #ff7a09);
  text-align: center;
  font-family: Geometria;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  transition: color 250ms linear;
  transition-property: color, background;
  cursor: pointer;
}

.buttonFalse:hover {
  background: var(--Orange, #ff7a09);
  color: var(--text-hover);
}
